export const BRAND = {
  primaryDark: '#171829',
  primaryD: '#ed1c24',
  primary: '#ed1c24',
  primaryL: '#b4ffb4',
  secondaryDark: '#171829',
  secondaryD: '#dfb807',
  secondary: '#78B428',
  secondaryL: '#78B428',
  tertiaryD: '#a21021',
  tertiary: '#d1152a',
  tertiaryL: '#ed4f61',
  favourite: '#ed1c24',
  demoBtn: '#0000009c',
  AuthButton: '#ec932b',
  newAuthButton: '#8D161B',
  newAuthButtonActive: '#570A0D',
  walletBG: '#8D161B',
  red: '#E00E16',
  gold: '#ffde3d',
  redD: '#A7060C',
  yellow: '#B79B0A',
};

export const GREYS = {
  black: '#000000',
  silverD: '#171829',
  silver: '#2c2c32',
  silverL: '#CED2EE',
  doveD: '#999999',
  dove: '#e6e6e6',
  doveL: '#fafafa',
  white: '#ffffff',
  action: '#263238',
  wheat: '#C4C4C4',
  blue: '#161E5F',
  blueD: '#02051A',
  blueL: '#2B336D',
  blueN: '#202654',
  activeG: '#3DBA21',
  greenL: '#142229',
  blueBG: '#222d79',
  blueG: '#1A225E',
  blueM: '#040931',
  blueGI: '#282E5E',
  greenD: '#126925',
  greyB: '#535353',
  greyL: '#9EA2BE',
  greyM: '#33333D',
  silverN: '#BEC4EF',
  whiteL: ' #F8FFEF',
  captainS: '#CED2EE',
  blackL: '#161729',
  dangerL: '#E32F2E',
};

export const FUNCTIONAL = {
  accountD: '#2b2e4c',
  accountC: '#36b37e',
  accountB: '#17182a',
  documentUB: '#2B2E4B',
  documentU: '#171829',
  modalFont: '#c4c4c4',
  timerB: '#2c2c32',
  timerBB: '#4cc4ff',
  positiveD: '#258e5d',
  positive: '#36b37e',
  positiveL: '#d8f3e8',
  negativeD: '#d30909',
  negative: '#ff3939',
  negativeL: '#feebeb',
  guideD: '#177db0',
  guide: '#4cc4ff',
  guideL: '#ebf8ff',
  warningD: '#c85809',
  warning: '#ff8c21',
  warningL: '#fddfc9',
  info: '#0c2b3c',
  background: '#1A1F4A',
  darkBorder: '#3D4271',
  backgroundD: '#272E6A',
  backgroundL: '#3B4CC8',
  backgroundR: '#2B305B',
  backgroundO: '#2A3270',
  buttonB: '#2D388D',
  pending: '#E8AF5A',
  captainD: '#131733',
  captainB: '#212C84',
  captainL: '#1C2466',
  captainBD: '#0B1148',
  captainBC: '#373F84',
};

export const FONT = {
  dark: '#000000',
  darkMute: '#15171e',
  light: '#2a2e3c',
  lightMute: '#828e94',
  primary: '#ffffff',
  secondary: '#ed1c24',
  tertiary: '#919DFF',
};

export const SHADOW = '0 0 10px rgba(5, 76, 129, 0.3)';

export const BGI =
  'linear-gradient(-14deg, rgba(84, 219, 213, 0) 0%, #00e400 100%)';

export const CARD = {
  display: 'flex',
  flexDirection: 'column',
  background: GREYS.silverD,
  boxShadow: SHADOW,
  borderRadius: 4,
  marginBottom: 16,
};
export const SUPPLEMENTARY = {
  black: '#000000',
};

export const BACKGROUND = {};
