import {
  StyledCategoryTab,
  C,
  LabelSpan,
} from 'CORE__UI/navigation/EventsCategoryTab/core__events-category-tab-styles';
import styled from 'styled-components';
import { GREYS, BRAND } from '../globals/colours';
import { BREAKPOINT_S } from '../globals/breakpoints';
const StyledCategoryTab_JN = styled(StyledCategoryTab)`
  color: ${({ active }) => (active ? GREYS.white : GREYS.doveD)};
  background: ${GREYS.silverD};
  margin-right: 8px;
  padding: 0 16px 0 12px;
  box-shadow: none;
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column;
    height: 60px;
    min-width: 60px;
    font-size: 10px;
    letter-spacing: 0.35px;
    text-align: center;
    padding: 0 4px;
    i {
      font-size: 22px;
      margin-bottom: 6px;
      margin-right: 0;
    }
  }
`;
const C_JN = styled(C)`
  background: ${({ active }) => (active ? BRAND.favourite : GREYS.doveD)};
  color: ${({ active }) => (active ? GREYS.black : GREYS.silver)};
  font-weight: 400;
`;
export { StyledCategoryTab_JN as StyledCategoryTab, C_JN as C, LabelSpan };
