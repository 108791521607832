import { EAr } from 'CORE__UI/globals/ExpandArrow/core__expandArrowStyles';
import styled from 'styled-components';
import { GREYS } from './colours';

export const EAR = styled(EAr)`
  transform: ${({ expanded }) =>
    expanded ? 'rotate(360deg)' : 'rotate(0deg)'};
  padding-top: ${({ expanded }) => (expanded ? '8px' : '0px')};
  &:after {
    border-color: ${GREYS.white};
    border-width: 0 3px 3px 0;
  }
`;

export { EAR as EAr };
