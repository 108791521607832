import styled from 'styled-components';
import { FONT } from '../../globals/colours';

import { ED, OSDW, Live } from 'CORE__UI/apps/EventsApp/core__eventDate';

const ED_JN = styled(ED)`
  color: ${FONT.lightMute};
  line-height: 20px;
  margin: 2px 0;
`;
export { ED_JN as ED, OSDW, Live };
