import styled, { css } from 'styled-components';
import { GREYS, BRAND } from '../../globals/colours';
import { BREAKPOINT_S } from '../../globals/breakpoints';
import {
  League,
  MarketRefLabel,
  MarketWagerLabel,
  MarketRefLabelContainer,
  EventMarketEmpty,
} from 'CORE__UI/apps/EventsApp/core__eventsTableBodySection';

const L_EX = styled(League)`
  color: ${GREYS.doveD};
  border-bottom: 1px solid ${GREYS.silver};
  border-top: none;
  &:first-child {
    border-top: 1px solid ${GREYS.silver};
  }
  background: ${GREYS.silverD};
  align-items: center;
  justify-content: center;
`;

const MRLC_EX = styled(MarketRefLabelContainer)`
  border-color: ${GREYS.silver};
  border-right: 1px solid ${GREYS.silver};
  margin-right: 8px;
  &:last-child {
    margin-right: 4px;
  }
  ${({ americanLayout }) =>
    americanLayout &&
    css`
      flex: 0.35;
      margin-right: 0.5em;
      border: none;
      &:last-child {
        margin-right: 0;
      }
    `}
`;

const MarketRefLabel_JN = styled(MarketRefLabel)`
  flex: 1;
  color: ${BRAND.secondary};
`;
const MarketWagerLabel_JN = styled(MarketWagerLabel)`
  @media screen and (max-width: ${BREAKPOINT_S}) {
    width: 50px;
  }
`;
export {
  L_EX as League,
  MarketRefLabel_JN as MarketRefLabel,
  MarketWagerLabel_JN as MarketWagerLabel,
  MRLC_EX as MarketRefLabelContainer,
  EventMarketEmpty,
};
