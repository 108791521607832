import {
  ViewMoreBar,
  ViewMoreLabel,
} from 'CORE__UI/apps/EventsApp/core__eventsTableBody';
import styled from 'styled-components';
import { GREYS } from '../../globals/colours';
const ViewMoreBar_JN = styled(ViewMoreBar)`
  border: none;
  color: ${GREYS.white};
  font-weight: 400;
`;
export { ViewMoreBar_JN as ViewMoreBar, ViewMoreLabel };
