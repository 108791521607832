import {
  LW,
  StyledEventsLink,
  Wrap,
} from 'CORE__UI/navigation/TopLink/core__topLinkStyles';
import styled, { css } from 'styled-components';
import { BREAKPOINT_S } from '../globals/breakpoints';
import { GREYS, FONT } from 'UI/globals/colours';

const StyledEventsLink_OL = styled(StyledEventsLink)`
  color: ${FONT.tertiary};
  font-size: 14px;
  line-height: 20px;
  padding: 8px 16px;
  border-radius: 20px;
  border: 2px solid ${GREYS.blueBG};
  font-weight: 700;
  @media (hover) {
    &:hover {
      border: 2px solid ${FONT.tertiary};
      box-shadow: 0px 0px 20px #001dff;
      color: ${FONT.tertiary} !important;
    }
  }
  @media (max-width: ${BREAKPOINT_S}) {
    margin: 8px 12px 8px 0;
  }
  ${({ active }) =>
    active &&
    css`
      border: 2px solid ${FONT.tertiary};
      box-shadow: 0px 0px 20px #001dff;
      color: ${FONT.tertiary};
      border-bottom-color: ${FONT.tertiary} !important;
    `}
`;

const Wrap_OL = styled(Wrap)`
  @media (max-width: ${BREAKPOINT_S}) {
    overflow-x: scroll;
    white-space: nowrap;
  }
`;

export { LW, StyledEventsLink_OL as StyledEventsLink, Wrap_OL as Wrap };
