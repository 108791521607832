import styled from 'styled-components';
import { BRAND, GREYS } from '../../globals/colours';

import {
  EventName,
  EventDate,
  EventMarket,
  EventsRow,
  MoreMarketsRow,
  RightArrow,
  StyledAnchor,
  EmptyAmericanMarket,
} from 'CORE__UI/apps/EventsApp/core__eventsTableRow';
import React from 'react';

const EN_EX = styled(EventName)`
  color: ${GREYS.white};
`;
const MoreMarketsRow_JN = styled(MoreMarketsRow)`
  border-bottom: 1px solid ${GREYS.silver};
  background: ${GREYS.silverD};
  padding-top: 0;
`;
const StyledAnchor_JN = styled(StyledAnchor)`
  display: flex;
  flex-direction: column;
  height: 100%;
  color: ${BRAND.favourite};
`;
const StyledAnchorArrow = styled(StyledAnchor)`
  color: ${BRAND.favourite};
`;
const RightArrow_JN = styled(RightArrow)`
  border-color: ${BRAND.favourite};
  padding: 4px;
  margin-right: 8px;
  margin-left: 4px;
`;
const EventsRow_JN = styled(EventsRow)`
  align-items: stretch;
`;
export {
  EN_EX as EventName,
  EventDate,
  EventMarket,
  EventsRow_JN as EventsRow,
  EmptyAmericanMarket,
  MoreMarketsRow_JN as MoreMarketsRow,
  RightArrow_JN as RightArrow,
  StyledAnchor_JN as StyledAnchor,
  StyledAnchorArrow,
};
export const EventFixture = React.Fragment;
