import {
  EventsCell,
  StyledOddsButton,
} from 'CORE__UI/apps/EventsApp/core__market';
import styled from 'styled-components';
import { GREYS } from '../../globals/colours';

const EventsCell_JN = styled(EventsCell)`
  border-right: ${props =>
    props.americanLayout ? `none` : ` 1px solid ${GREYS.silver}`};
  flex: ${props => (props.americanLayout ? `0.5` : `1`)};
  padding-right: ${props => (props.americanLayout ? `8px` : `0`)};
  margin-right: 0;
  &:last-child {
    padding-right: 0;
    border-left: none;
    margin-left: ${props => (props.americanLayout ? `0` : `8px`)};
  }
`;
const StyledOddsButton_JN = styled(StyledOddsButton)`
  margin: ${props => (props.americanLayout ? `0 8px 8px 0` : `0 8px 0 0`)};
  &:last-child {
    margin-right: 8px;
  }
  height: ${props => (props.americanLayout ? `32px` : `48px`)};
`;
export { EventsCell_JN as EventsCell, StyledOddsButton_JN as StyledOddsButton };
