import { StyledSelect } from 'CORE__UI/lines/dropdowns/MarketSelect/core__marketSelectStyles';
import styled from 'styled-components';
import { GREYS } from '../globals/colours';
const StyledSelect_JN = styled(StyledSelect)`
  div[class*='singleValue'] {
    color: ${GREYS.white};
    &:after {
      border-color: ${GREYS.white};
    }
  }
`;
export { StyledSelect_JN as StyledSelect };
