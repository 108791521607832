import { TeamName, Team, TeamScore } from 'CORE__UI/apps/EventsApp/core__teams';
import styled, { css } from 'styled-components';
import { GREYS } from '../../globals/colours';

const TeamName_JN = styled(TeamName)`
  color: ${GREYS.white};
  padding-left: 8px;
  margin-bottom: 8px;
  ${({ isServing }) =>
    isServing &&
    css`
      padding-left: 4px;
    `}
`;

const Team_JN = styled(Team)`
  padding: 0;
  flex: 1;
  align-items: center;
`;
const TeamScore_JN = styled(TeamScore)`
  font-weight: 700;
  margin-right: 16px;
  color: ${GREYS.white};
  margin-bottom: 8px;
`;
export { TeamName_JN as TeamName, Team_JN as Team, TeamScore_JN as TeamScore };
