import styled from 'styled-components';
import { GREYS } from '../../globals/colours';

import {
  StyledMarketName,
  SMN,
  StyledCatName,
  StyledRow,
} from 'CORE__UI/apps/EventsApp/core__eventsTableHeader';

const SCN_EX = styled(StyledCatName)`
  color: ${GREYS.white};
`;
const StyledRow_JN = styled(StyledRow)`
  border-bottom: 1px solid ${GREYS.silver};
`;

const SMN_JN = styled(SMN)`
  color: ${GREYS.white};
`;
export {
  StyledMarketName,
  SMN_JN as SMN,
  SCN_EX as StyledCatName,
  StyledRow_JN as StyledRow,
};
