import {
  SOB,
  NAB,
  SS,
  OD,
  OU,
  CS,
  CSCnt,
  LS,
} from 'CORE__UI/buttons/OddsButton/core__oddsButtonStyles';
import styled, { css } from 'styled-components';
import { BRAND, GREYS, FONT } from '../globals/colours';

const SOB_JN = styled(SOB)`
  background-color: ${GREYS.silver};
  color: ${BRAND.secondary};
  ${({ inactive }) =>
    !inactive &&
    css`
      @media (hover) {
        &:hover {
          box-shadow: 0 0 0 1px
            ${({ selected }) => (selected ? BRAND.primaryL : BRAND.primaryL)};
        }
      }
    `}
  ${({ inactive }) =>
    inactive &&
    css`
      background-color: ${GREYS.silverD};
      border: 1px solid ${FONT.light};
      color: ${GREYS.silver};
    `}
`;
const NAB_JN = styled(NAB)`
  background-color: ${GREYS.silverD};
  color: ${GREYS.silverD};
  border: 1px solid ${FONT.light};
`;
const LS_JN = styled(LS)`
  color: ${GREYS.doveD};
`;
export { SOB_JN as SOB, NAB_JN as NAB, SS, OD, OU, CS, CSCnt, LS_JN as LS };
