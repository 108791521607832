import { timeFormats } from 'Services/global/core__time-formats';

export default {
  countryCodeArray: [
    { value: 'IP31', label: 'IP31' },
    { value: 'MK45', label: 'MK45' },
    { value: 'SP11', label: 'MSP11' },
    { value: 'GL7', label: 'GL7' },
    { value: 'YO62', label: 'YO62' },
    { value: 'SO51', label: 'SO51' },
    { value: 'YO17', label: 'YO17' },
  ],
  captainup_api_key: '621ce0504f4b2408c5fb1335',
  captainup_client_token: 'b1bf8d105e89d4a73020e25db6c1dc0631353f10',
  home: '/',
  clientId: 'ompcskweb',
  clientSecret: 'd0XqdznmNk',
  mobileClientId: 'ompcskweb',
  mobileClientSecret: 'd0XqdznmNk',
  defaultCurrency: 'EUR',
  distributedBundle: true,
  bundle: {
    name: 'react,ompcsk',
    languanges: [
      { id: 1, value: 'en' },
      { id: 13, value: 'sk_SK' },
    ],
  },
  sportsBundle: {
    id: 20,
    name: 'sports',
    languanges: [{ id: 1, value: 'en' }],
  },
  staticPath: {
    // base path
    basePath: 'https://assets.fsbtech.com/',
    // bucket name
    bucketReact: 'react/',
    bucketImages: 'images/',
    bucketOsiros: 'osiros/',
    // folder name
    folderIcon: 'icons/',
    folderMisc: 'misc/',
    folderFlags: 'flags/',
    folderPayments: 'payments/',
    folderVirtuals: 'virtuals/',
  },
  casino: {
    assetsUrl: 'https://assets.fsbtech.com/images/casino',
  },
  timeFormats,
  currency: 'EUR',
  countryDrop: [
    {
      value: 'SK',
      label: 'SLOVAKIA',
    },
  ],
  casinoDemoPlay: 'fun',
  casinoModalClose: true,
};
